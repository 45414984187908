import axios from "axios";
import {
  corsAny,
  urlOldApiEndpoint,
  urlScriptCaseDirect,
  apiBaseUrl,
  apiGatewayUrlNonLocal,
} from "../indexv2";

const quote_2_open_order = async (data) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");

    axios
      .post(
        `${urlScriptCaseDirect}/api_quote2invoice2_vendapp/?token=${token}`,
        {
          ...data,
        },
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

const cancel_order = (data) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "put",
      url: `${apiBaseUrl}/orders/cancel`,
      data,
      headers: { authorization: token },
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => reject(err));
  });

const close_order = (data) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "put",
      url: `${apiBaseUrl}/orders/close`,
      data,
      headers: { authorization: token },
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => reject(err));
  });

const get_orders_types = async () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");

    axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/types`,
      headers: { authorization: token },
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};
const get_orders_term = async () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");

    axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/terms`,
      headers: { authorization: token },
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

const create_payment = (data) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "POST",
      url: `${apiBaseUrl}/orders/payment`,
      data,
      headers: { authorization: token },
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => reject(err));
  });

const get_order_activities = (order) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "GET",
      url: `${apiBaseUrl}/activities?order=${order}`,
      headers: { authorization: token },
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => reject(err));
  });

const save_quote = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "PUT",
      url: `${apiBaseUrl}/orders/hdr`,
      headers: { authorization: token },
      data,
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const update_equipment_on_order = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/orders/release/generate_invoice_items_container`,
      headers: { authorization: token },
      data,
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const assign_release = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/orders/release`,
      headers: { authorization: token },
      data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};
const set_originalEntityID = async (data) => {
  const token = localStorage.getItem("access_token");
  return await axios({
    method: "POST",
    url: `${apiBaseUrl}/orders/invoice/createInvoiceOriginalEntityID`,
    headers: { authorization: token },
    data,
  });
};

const get_equipment_on_order = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/line/item/equipment?lineItemId=${data}`,
      headers: { authorization: token },
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const update_order_pipeline = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "PUT",
      url: `${apiBaseUrl}/orders/pipeline`,
      headers: { authorization: token },
      data,
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const update_equipment_on_order_single = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/orders/line/item/equipment`,
      headers: { authorization: token },
      data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const update_equipment_on_order_batch = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/orders/equipment/batch`,
      headers: { authorization: token },
      data,
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const get_payment_details = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/payment?orderId=${data}`,
      headers: { authorization: token },
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const get_release_details = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/release?docNo=${data}`,
      headers: { authorization: token },
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const get_EOO_sub_details = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/inventory/eoo/equipment?equipmentid=${data}`,
      headers: { authorization: token },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const remote_upload_pdf_to_order = async (data) => {
  const token = localStorage.getItem("access_token");

  // return { pass: true, message: "skip" };
  try {
    const res = await axios({
      method: "POST",
      url: `${process.env.VUE_APP_VENDOR_DIRECT_URL}/files/upload/remote`,
      // url: `${apiBaseUrl}/files/upload/remote`,
      headers: { authorization: token },
      data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const get_invoice_meta_details = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/invoice/meta/?order_id=${data}`,
      headers: { authorization: token },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const create_invoice_meta = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/orders/invoice/meta`,
      headers: { authorization: token },
      data,
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const update_invoice_meta = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "PUT",
      url: `${apiBaseUrl}/orders/invoice/meta`,
      headers: { authorization: token },
      data,
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};
const delete_invoice_meta = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "DELETE",
      url: `${apiBaseUrl}/orders/invoice/meta`,
      headers: { authorization: token },
      data,
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};
// invoice_meta_id

const get_comments = async (docRef) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/notes/all?doc_ref=${docRef}`,
      headers: { authorization: token },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};
const update_comment = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "PUT",
      url: `${apiBaseUrl}/notes?id=${data.id}`,
      data: {
        note: data.notes_text,
      },
      headers: { authorization: token },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};
const delete_comment = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "DELETE",
      url: `${apiBaseUrl}/notes?id=${data.id}`,
      headers: { authorization: token },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};
const create_comment = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/notes`,
      headers: { authorization: token },
      data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const get_notes_view_acces = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/notes/viewAccess`,
      headers: { authorization: token },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const update_qty_summ_booked = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/orderInventoryAggQtySumm`,
      headers: { authorization: token },
      data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const trans_log_order = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/trans/log/order`,
      headers: { authorization: token },
      data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const triggerReleaseHeader = async ({
  customerCode,
  docNo,
  depotIDSelected,
}) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/orders/release/header`,
      headers: { authorization: token },
      data: {
        code: customerCode,
        docNo,
        depotIDSelected,
      },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};
const available_container_no = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/orders/container_no`,
      headers: { authorization: token },
      data: data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const getTemplateEOOTable = async (item_id) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/template/eootable?item_id=${item_id}`,
      headers: { authorization: token },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const set_qty_line_item = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "PUT",
      url: `${apiBaseUrl}/orders/partialcancel`,
      headers: { authorization: token },
      data,
    });
  } catch (error) {
    throw error;
  }
};

const remove_line_item = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "DELETE",
      url: `${apiBaseUrl}/orders/partialcancel`,
      headers: { authorization: token },
      data,
    });
  } catch (error) {
    throw error;
  }
};

const remove_line_item_bb_soft = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "DELETE",
      url: `${apiBaseUrl}/orders/partialcancel`,
      headers: { authorization: token },
      data,
    });
  } catch (error) {
    throw error;
  }
};

const get_single_credit = async (docNo) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/credit`,
      headers: { authorization: token },
      params: {
        invoice_no: docNo,
      },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};
const get_cust_balance_credit = async (custID) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/credit/customer?custID=${custID}`,
      headers: { authorization: token },
      params: {
        custID,
      },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const apply_credit = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/orders/credit/apply`,
      headers: { authorization: token },
      data,
    });
  } catch (error) {
    throw error;
  }
};
const delete_EOO = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "DELETE",
      url: `${apiBaseUrl}/orders/detlpartialcancel`,
      headers: { authorization: token },
      data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

const get_releases_canceled = async (docNo) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/release/archive`,
      headers: { authorization: token },
      params: {
        docNo,
      },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};
const get_credit_balance = async (docNo) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/credit/invoice`,
      headers: { authorization: token },
      params: {
        invoice_no: docNo,
      },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const get_cancel_prior_pipeline = async (orderId) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/cancelpriorpipeline`,
      headers: { authorization: token },
      params: {
        orderId,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
const get_inventory_details = async (containerNo) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/inventorydetails`,
      headers: { authorization: token },
      params: {
        containerNo,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

const save_added_line_items = (data) => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "POST",
    url: `${apiBaseUrl}/orders/additems`,
    headers: { authorization: token },
    data,
  });
};
const create_draft_order = async ({ data }) => {
  const token = localStorage.getItem("access_token");

  const res = await axios({
    method: "POST",
    url: `${apiBaseUrl}/orders/draft`,
    headers: { authorization: token },
    data,
  });
  return res;
};
const get_draft_order = async () => {
  const token = localStorage.getItem("access_token");

  const res = await axios({
    method: "GET",
    url: `${apiBaseUrl}/orders/draft`,
    headers: { authorization: token },
  });
  return res;
};
const delete_draft_order = async () => {
  const token = localStorage.getItem("access_token");

  const res = await axios({
    method: "DELETE",
    url: `${apiBaseUrl}/orders/draft`,
    headers: { authorization: token },
  });
  return res;
};
const delete_payment = async (invoice_no, payment_id) => {
  const token = localStorage.getItem("access_token");

  return await axios({
    method: "DELETE",
    url: `${apiBaseUrl}/orders/payment`,
    headers: { authorization: token },
    data: {
      invoice_no,
      payment_id,
    },
  });
};

const send_releases = async (data) => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_FILES_URL_API}/orders/release/serveremail`,
    headers: { authorization: token },
    data,
  });
};

const create_dv_container = async (data) => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "POST",
    url: `${apiBaseUrl}/orders/adddvcontainer`,
    headers: { authorization: token },
    data,
  });
};

const set_invoice_bill = async (data) => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "POST",
    url: `${apiBaseUrl}/orders/invoice/bill`,
    headers: { authorization: token },
    data,
  });
};
const get_order_type = async (data) => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "GET",
    url: `${apiBaseUrl}/orders/single/type/${data}`,
    headers: { authorization: token },
  });
};

export {
  update_qty_summ_booked,
  cancel_order,
  get_orders_term,
  get_orders_types,
  create_payment,
  get_order_activities,
  quote_2_open_order,
  save_quote,
  update_equipment_on_order,
  get_equipment_on_order,
  update_order_pipeline,
  update_equipment_on_order_single,
  update_equipment_on_order_batch,
  get_payment_details,
  get_release_details,
  close_order,
  assign_release,
  get_EOO_sub_details,
  remote_upload_pdf_to_order,
  get_invoice_meta_details,
  create_invoice_meta,
  get_comments,
  update_comment,
  delete_comment,
  create_comment,
  get_notes_view_acces,
  update_invoice_meta,
  trans_log_order,
  delete_invoice_meta,
  triggerReleaseHeader,
  getTemplateEOOTable,
  available_container_no,
  set_qty_line_item,
  remove_line_item,
  get_single_credit,
  apply_credit,
  delete_EOO,
  get_cust_balance_credit,
  get_releases_canceled,
  get_credit_balance,
  get_cancel_prior_pipeline,
  get_inventory_details,
  save_added_line_items,
  create_draft_order,
  get_draft_order,
  delete_draft_order,
  delete_payment,
  send_releases,
  create_dv_container,
  set_invoice_bill,
  set_originalEntityID,
  get_order_type,
};
